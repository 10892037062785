// 严凯 V2.2.0
<template>
  <section style="margin: 0 24px">
    <section class="form-style">
      <a-form layout="inline">
        <a-form-item label="" @click="clickFocus">
          <a-input
            ref="inputbox"
            class="form-input"
            allowClear
            :maxlength="20"
            v-model:value="searchVal"
            placeholder="请输入流程名称"
          >
            <template #prefix>
              <img src="@/assets/svg/search.svg" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-btns">
          <a-button @click="searchList" class="form-btn">查询</a-button>
          <!-- <a-button class="form-btn" @click="openModal('add')">添加流程</a-button> -->
        </a-form-item>
      </a-form>
    </section>
    <section class="table-style">
      <div class="table-head">
        <span class="title">流程列表</span>
        <a-button class="add-btn" @click="$router.push('/businessManage/process/add')"
          ><PlusOutlined class="add-icon" />添加流程</a-button
        >
      </div>
      <a-table
        :columns="processColumns"
        :dataSource="dataSource"
        :pagination="pagination"
        @change="handleTanChange"
        :loading="tableLoading"
        rowKey="flowId"
        ref="tableRef"
        :scroll="{ x: true }"
      >
        <!-- 序号 -->
        <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <!-- 流程名称 -->
        <template #flowName="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- 创建人 -->
        <template #foundName="{ text }">
          <span :title="text">{{ text ?? '-' }}</span>
        </template>
        <!-- <template #businessType="{ text }">{{ text === 2 ? '归档审批' : ' 用印审批' }}</template> -->
        <!-- 流程类型 -->
        <template #flowType="{ text }">
          <span v-if="text === 1">固定流程</span>
          <span v-if="text === 2">自由流程</span>
          <span v-if="text === 3">补盖流程</span>
        </template>
        <template #flowScope="{ text, record }"
          ><span class="process-range" :title="processRangeFilterFn(record.flowScopeVO) || '全体可见'">
            {{ text === 1 ? '全体可见' : record.flowScopeVO ? processRangeFilterFn(record.flowScopeVO) : '-' }}
          </span></template
        >
        <template #action="{ record }">
          <!-- <a @click="openModal('edit', record)" style="margin-right: 16px" class="table-text-color">修改</a> -->
          <a @click="processEdit(record)" style="margin-right: 16px" class="table-text-color">修改</a>
          <a @click="openModal('delete', record)" class="table-text-color" v-if="record.flowType !== 3">删除</a>
        </template>
      </a-table>
    </section>
    <modal
      v-model:visible="visible"
      @modalSubmit="modalSubmit"
      :types="actionType"
      :current="current"
      v-model:loading="loading"
    />
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, createVNode } from 'vue'
import { processColumns } from '../columns'
import modal from './components/modal'
import tableHeader from '@/views/components/tableHeader'
import { getProcessList, deleteProcess, addProcess, editProcess, getApplyProcessStaff } from '@/apis/businessManage'
import { Modal } from 'ant-design-vue'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import { SET_APPLY_PROCESS_LIST } from '@/store/globalData/mutations-type'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { paginationParams } from '@/utils/constData'
import { PlusOutlined, ExclamationOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    tableHeader,
    modal,
    PlusOutlined
  },
  setup() {
    const inputbox = ref(null)
    const store = useStore()
    const $router = useRouter()
    const tableRef = ref()
    const state = reactive({
      searchVal: undefined,
      processColumns,
      dataSource: [],
      pagination: {
        ...paginationParams
      },
      visible: false,
      current: undefined,
      loading: false,
      actionType: 'add',
      tableLoading: true,
      currentNum: 1
    })

    const getList = async () => {
      state.tableLoading = true
      const params = {
        searchFlowName: state.searchVal,
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize
      }
      const res = await getProcessList(params)
      state.dataSource = res.data
      console.log('列表数据', state.dataSource)
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }
    const handleTanChange = ({ current, pageSize }) => {
      state.pagination.current = current
      state.currentNum = 1
      state.pagination.pageSize = pageSize
      state.dataSource = []
      getList()
    }
    const openModal = (type, current) => {
      console.log('表格当前行记录', current)
      state.actionType = type
      const actionObjFn = {
        delete() {
          Modal.confirm({
            title: '确认删除！',
            content: '删除后无法恢复，是否删除？',
            centered: true,
            confirmLoading: state.loading,
            icon: createVNode(ExclamationOutlined),
            onOk: async () => {
              const res = await deleteProcess({ id: current.flowId })
              if (res.success) {
                cmsNotice('success', '删除成功')
                state.pagination.total %
                  (state.pagination.current * state.pagination.pageSize - state.pagination.pageSize) ===
                  1 && (state.pagination.current -= 1)
                getList()
              }
              state.loading = false
            }
          })
        },
        async edit() {
          // const res = await getApplyProcessStaff({ id: current.flowId })
          // res.data = res.data.filter((item) => item)
          // state.current.flowUserRelsVO = res.data.map((item, index) => {
          //   item.index = index
          //   return item
          // })
          state.current = current
          state.visible = true
        }
      }
      actionObjFn[type] ? actionObjFn[type]() : (state.visible = true)
    }
    const modalSubmit = val => {
      const actionObjFn = {
        add: async function() {
          const res = await addProcess(JSON.stringify(val))
          if (res.success) {
            store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`)
            cmsNotice('success', '添加成功')
            getList()
          }
          state.visible = false
          state.loading = false
        },
        edit: async function() {
          const res = await editProcess(JSON.stringify({ ...val, flowId: state.current.flowId }))
          if (res.success) {
            store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`)
            cmsNotice('success', '修改成功')
            getList()
            state.visible = false
          }

          state.loading = false
        }
      }
      actionObjFn[state.actionType]()
    }
    const searchList = () => {
      // 清空前后空格
      if (state.searchVal) {
        state.searchVal = state.searchVal.replace(/\s*/g, '')
      }
      state.pagination.current = state.searchVal ? 1 : state.currentNum
      getList()
    }

    const processRangeFilterFn = list => {
      // 页面展示的流程人或部门
      // if (!list) return type ? '' : '--'
      if (!list) return '-'
      const rangeList = list
        .map(item =>
          item.departmentName && item.staffName
            ? item.departmentName + ',' + item.staffName
            : item.departmentName || item.staffName
        )
        .toString()
        .split(',')
      return rangeList.join(',')
      // if (type) return rangeList.length > 3 ? rangeList.toString() : ''
      // return rangeList.length > 3 ? rangeList.slice(0, 3).toString() + '...' : rangeList.toString()
    }

    const processEdit = record => {
      localStorage.setItem('processCurrent', JSON.stringify(record))
      $router.push('/businessManage/process/edit?id=' + record.flowId)
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }

    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      searchList,
      openModal,
      modalSubmit,
      handleTanChange,
      processRangeFilterFn,
      processEdit,
      tableRef,
      clickFocus,
      inputbox
    }
  }
})
</script>

<style lang="scss" scoped>
.process-range {
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
</style>
